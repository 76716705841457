@media (max-width: 320px) {

  .footer-globalnav__listitem:not(:first-child) {
    margin-left: 25px!important;
  }

}


footer {
  padding-top: 30px;
  background-color: $primary;
  padding-bottom: 40px;
  font-size: 12px;
}

footer p {
  font-size: 12px;
}

footer a {
  color: #000;
  &:hover {
    color: #000;
  }
}

footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.footer-nav__listitem a {
  font-family: 'BrandonGrotesque-Black';
}

.footer-globalnav__listitem a {
  text-transform: uppercase;
}

//.footer-socialnav__listitem {
//  width: 25px;
//}

.footer-socialnav__listitem a {
  text-indent: -9999px;
  //overflow: hidden;
  display: inline-block;
  float: left;
}

.footer-socialnav__listitem a:before {
  float: left;
  font-size: 24px;
  text-indent: 0;
}

.footer-globalnav__listitem,
.footer-socialnav__listitem {
  float: left;
}

.footer-socialnav__listitem {
  min-width: 28px;
}

.footer-lastnav__listitem {
  float: left;
  margin-right: 20px;
}

.footer-socialnav__listitem:not(:first-child) {
  margin-left: 20px;
}

.footer-socialnav__listitem--app {
  margin-left: 20px;
  float: left;
  > a {
    > img {
      height: 24px;
    }
  }
}


  @include media-breakpoint-down(sm) {

  .footer-nav__listitem {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .footer-nav__listitem:not(:last-child) {
    border-bottom: 1px solid #000;
  }

  .footer-nav {
    margin-bottom: 20px;
  }

  .footer-globalnav,
  .footer-lastnav {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
  }

  .footer-globalnav:after,
  .footer-lastnav:after,
  .footer-socialnav:after {
    display: block;
    clear: both;
    content: "";
  }

  .footer-lastnav,
  .footer-socialnav {
    margin-bottom: 20px;
  }

  .footer-globalnav__listitem:not(:first-child) {
    margin-left: 30px;
  }

}

@include media-breakpoint-down(md) {

  .footer-lastnav:after {
    display: block;
    clear: both;
    content: "";
  }

}


@include media-breakpoint-between(sm, md) {

  .footer-lastnav {
    margin-bottom: 15px;
  }

}


@include media-breakpoint-between(md, lg) {

  .footer-nav {
    margin-bottom: 15px;
  }

}


@include media-breakpoint-up(md) {

  footer {
    font-size: 15px;
  }

  footer p {
    font-size: 15px;
  }

  .footer-nav:after {
    display: block;
    clear: both;
    content: "";
  }

  .footer-socialnav__listitem--app {
    margin-left: 20px;
    float: left;
    border-left: 1px solid black;
    padding-left: 20px;
    line-height: 28px;
    > a {
      > img {
        margin-top: -5px;
        height: 28px;
      }
    }
  }

  .footer-nav__listitem {
    float: left;
    margin-right: 25px;
  }

  .footer-globalnav__listitem:not(:first-child) {
    margin-left: 25px;
  }

  .footer-socialnav__listitem a:before {
    font-size: 28px;
  }

  .footer-divider {
    margin: 25px 0;
  }

  .footer-copyright {
    margin-top: 10px;
  }

}

@include media-breakpoint-up(lg) {

  .footer-socialnav {
    float: right;
  }

}

@include media-breakpoint-up(xl) {

  footer {
    padding-top: 60px;
    padding-bottom: 200px;
  }

  .footer-nav:after {
    display: none;
  }

  .footer-nav {
    float: left;
  }

  .footer-globalnav {
    float: right;
  }

  .footer-lastnav {
    float: left;
  }

}