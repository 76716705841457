.cc-banner.cc-bottom {
  background-color: #000!important;
  color: #fff!important;
  font-family: BrandonText-Light;
  font-size: 17px!important;
}

.cc-message {
  line-height: 17px;
}

.cc-message > a {
  color: #fff;
  text-decoration: underline;
}

.cc-btn {
  background-color: $primary!important;
}


@media (min-width: 1600px) {

  .cc-banner.cc-bottom {
    padding-left: 230px!important;
    padding-right: 230px!important;
  }

  .cc-message {
    padding-right: 215px;
  }

}