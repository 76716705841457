#service-level-info {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
  //margin-top: -40px;
  margin-bottom: 0;
}

#service-level-info p,
#service-level-info .fourboxes__itemtitle {
  color: #fff!important;
}

.trigger-srvclvl-info {
  cursor: pointer;
}

#close-service-level-info {
  float: right;
  margin-right: 40px;
  margin-bottom: 20px;
}

.box-srvclvl {
  background-color: $primary;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  &:before {
    font-family: 'icomoon';
    display: block;
    text-align: center;
  }
}

.box-srvclvl-new {
  margin-bottom: 20px;
  width: 100%;
}

.box-srclvl__num {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: 'BrandonGrotesque-Black';
  font-size: 25px;
  line-height: 25px;
  .sup {
    position: relative;
    top: -8px;
    text-transform: uppercase;
    font-size: 12px;
  }

}

.box-srvclvl--1 {
  &:before {
    content: '\e908';
  }
}

.box-srvclvl--2 {
  &:before {
    content: '\e905';
  }
}

.box-srvclvl--3 {
  &:before {
    content: '\e907';
  }
}

.box-srvclvl--4 {
  &:before {
    content: '\e906';
  }
}

@include media-breakpoint-down(xs) {
  .box-srvclvl {
    max-width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    &:before {
      font-size: 70px;
      line-height: 120px;
    }
  }

  .box-srvclvl-new {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
  .box-srvclvl {
    height: 245px;
    &:before {
      font-size: 150px;
      line-height: 245px;
    }
  }

  .box-srclvl__num {
    top: 25px;
    left: 25px;
    font-size: 50px;
    line-height: 50px;
    .sup {
      top: -20px;
      font-size: 20px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  .box-srvclvl {
    height: 335px;
    &:before {
      font-size: 200px;
      line-height: 335px;
    }
  }

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  .box-srvclvl {
    height: 215px;
    &:before {
      font-size: 130px;
      line-height: 215px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
  .box-srvclvl {
    height: 262.5px;
    &:before {
      font-size: 160px;
      line-height: 262.5px;
    }
  }

  #service-level-info {
    padding-top: 50px;
  }

  #service-level-info {
    position: fixed;
  }
}