.attach-documents {
  margin-top: 15px;
  margin-bottom: 10px;
}

.inputfile-hint {
  line-height: 38px;
  padding-left: 10px;
  float: left;
}

.contact-form {
  margin-bottom: 50px;
}

.application-form {
  margin-bottom: 50px;
}

.contact-form .form__item,
.application-form .form__item {
  margin-bottom: 10px;
}

.tiny-checkbox {
  font-family: 'BrandonGrotesque-Black';
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
}

.tiny-checkbox--application {
  font-size: 15px;
}

.tiny-checkbox__input {
  appearance: none;
  border: none;
  float: left;
}

.tiny-checkbox__input:checked,
.tiny-checkbox__input:not(:checked) {
  background: transparent;
  position: relative;
  visibility: hidden;
  margin: 0;
  padding: 0;
}


//IE Fallback
.tiny-checkbox__input--ie {
  visibility: visible!important;
}
.tiny-checkbox__input--ie + .tiny-checkbox__label:before {
  visibility: hidden!important;
}

.tiny-checkbox__input:checked + .tiny-checkbox__label::before,
.tiny-checkbox__input:not(:checked) + .tiny-checkbox__label::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  top: 5px;
  border: 2px solid $primary;
  background: #fff;
  margin-right: 10px;
}


input[type='checkbox']:hover  + .tiny-checkbox__label::before{
  background: $grey-bg;
  box-shadow: inset 0 0 0 2px white;
}

input[type='checkbox']:checked  + .tiny-checkbox__label::before {
  background: $primary;
  box-shadow: inset 0 0 0 2px white;
}


input[type='checkbox'] + label,
input[type='radio'] + label {
  cursor: pointer;
}

.filterbar__topline {
  line-height: 20px;
  margin-bottom: 25px;
}

.filter__section {
  border-top: 1px solid #c6c6c6;
  padding-top: 20px;
  padding-bottom: 20px;
}

.filter__section--servicelevel {
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 10px;
}

.filter .form__item {
  text-transform: uppercase;
}

.form__item {
  display: block;
  height: 38px;
  line-height: 38px;
  color: #000;
  font-family: 'BrandonText-Light';
  font-size: 15px;
  margin-bottom: 1px;
}

.form__item--small {
  height: 38px!important;
  line-height: 38px!important;
  font-size: 15px!important;
}

.form__item--type-text,
.form__item--type-textarea,
.form__item--type-file {
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  outline: none;
}

.form__item--type-textarea {
  height: 100px;
  line-height: 22px;
  padding-top: 10px;
}

.form__item--type-text::placeholder,
.form__item--type-textarea::placeholder {
  color: #000;
}

.form__item--active-select {
  background-color: $primary;
}

.form__item--type-checkbox {
  position: absolute;
  appearance: none;
  display: none;
}

.form__item--type-select {
  cursor: pointer;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  cursor: pointer
}

.form__item--type-file {
  max-width: 200px;
  float: left;
}

.form__item-indicator {
  display: block;
  padding-left: 15px;
  position: relative;
  cursor: pointer;
  > .button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
  }
  > .marker__value {
    display: none;
  }
  > .button--marker {
    display: block;
  }
  &:after {
    font-family: icomoon;
    font-size: 30px;
    margin-left: 20px;
    top: 1px;
    position: absolute;
  }
}

.form__item--type-checkbox:disabled + .form__item-indicator {
  color: #ccc;
}

.form__item--type-checkbox:checked + .form__item-indicator {
  background-color: $primary;
  > .button {
    display: block;
  }
  > .marker__value {
    display: block;
  }
  > .button--marker {
    display: none;
  }
}

.form__item--marker {
  position: absolute;

  .form__item-indicator {
    padding-left: 48px!important;
    padding-right: 10px;
  }
  
  .button {
    //position: relative;
    //float: left;
    left: 0;
    margin-right: 10px;
  }
}

.form__item-indicator--srvclv11:after {
  content: '\e90d';
}

.form__item-indicator--srvclv12:after {
  content: '\e90f';
}

.form__item-indicator--srvclv13:after {
  content: '\e910';
}

.form__item-indicator--srvclv14:after {
  content: '\e90e';
}

.select-wrapper {
  position: relative;
}

.select-wrapper > .button {
  position: absolute;
  right: 0;
  pointer-events: none;
}

.select-wrapper select {
  appearance: none;
  border: none;
  border-radius: 0;
  width: 100%;
  outline: none;
}

.select-wrapper select {
  width: 100%;
  padding-left: 15px;
}

.select-wrapper option {
  background-color: #fff;
}

.filter__reset {
  font-family: 'BrandonText-Light';
  border: none;
  background: none;
  cursor: pointer;
  margin-bottom: 30px;
  font-size: 13px;
  line-height: 18px;
  //outline: none;
}

.section--extended {
  .form__item--type-select {
    background-color: #fff;
  }
}


@include media-breakpoint-up(md) {

  .attach-documents {
    margin-top: 48px;
    margin-bottom: 15px;
  }

  .inputfile-hint {
    line-height: 57px;
  }

  .form__item {
    height: 57px;
    line-height: 57px;
    font-size: 19px;
  }

  .contact-form .form__item,
  .application-form .form__item {
    margin-bottom: 15px;
  }

  .form__item--type-textarea {
    height: 128px;
    line-height: 24px;
    padding-top: 16px;
  }

  .form__item-indicator {
    padding-left: 30px;
    &:after {
      font-size: 40px;
      top: 3px;
    }
  }

  .select-wrapper select,
  .form__item--type-text,
  .form__item--type-textarea,
  .form__item--type-file {
    padding-left: 30px;
  }

  .filter__reset {
    font-size: 19px;
    line-height: 24px;
  }

}


@include media-breakpoint-up(lg) {
  .col-filterbar {
    padding-right: 50px;
  }
}