@media (max-width: 320px) {

  .box-slider__itemtxt {
    font-size: 10px!important;
  }

}


.box-slider {
  margin-bottom: 50px!important;
}

.box-slider__item {
  display: block;
  box-shadow: 0 0 20px #ccc;
  margin: 20px 6px;
  padding: 10px;
  background-color: #fff;
  &:hover {
    .box-slider__itemtxt {
    color: $yellow-text;
    }
  }
}

.box-slider--solutions .box-slider__item {
  height: 80px;
}

.box-slider__item--fixed-height {
  height: 80px;
}

.box-slider__itemimg {
  margin: 0 auto;
}

.box-slider__itemtxt {
  font-family: 'BrandonGrotesque-Black';
  text-transform: uppercase;
  text-align: center;
  color: #000;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 16px;
}

.box-slider--references {
  margin-top: 20px;
}


@include media-breakpoint-down(sm) {

  .box-slider--references {
    margin-left: -12.5px!important;
    margin-right: -12.5px!important;
  }

}


@media (min-width: 576px) and (max-width: 767px) {

  .box-slider--solutions {
    margin-left: 6.5px;
    margin-right: 6.5px;
  }

}


@include media-breakpoint-up(md) {

  .box-slider__item {
    min-height: 120px;
    margin: 20px 12.5px;
  }

  .box-slider__itemtxt {
    font-size: 24px;
    line-height: 26px;
  }

  .box-slider__item--fixed-height {
    height: 120px;
  }

}