.box-facts {
  background-color: $primary;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  &:before {
    font-family: 'Linearicons-Free';
    display: block;
    text-align: center;
  }
}

.box-facts--users {
  &:before {
    content: '\e82b';
  }
}

.box-facts--earth {
  &:before {
    content: '\e853';
  }
}

.box-facts--euro {
  &:before {
    font-family: 'BrandonText-Light';
    content: '\20AC';
  }
}

.box-facts--phone-handset {
  &:before {
    content: '\e830';
  }
}

.box-facts--interior {
  &:before {
    font-family: 'icomoon';
    content: '\e90a';
  }
}

.box-facts--diamond {
  &:before {
    content: '\e845';
  }
}

.box-facts--sun {
  &:before {
    content: '\e807';
  }
}

.box-facts--100 {
  &:before {
    font-family: 'BrandonText-Light';
    content: '100%';
  }
}


@include media-breakpoint-down(xs) {
  .box-facts {
    max-width: 120px;
    margin-left: auto;
    margin-right: auto;
    height: 120px;
    &:before {
      font-size: 70px;
      line-height: 120px;
    }
  }

  .box-facts--interior {
    &:before {
      font-size: 90px;
    }
  }

  .box-facts--100 {
    &:before {
      font-size: 40px;
    }
  }
}


@include media-breakpoint-up(sm) {
  .box-facts {
    height: 160px;
    &:before {
      font-size: 90px;
      line-height: 160px;
    }
  }

  .box-facts--interior {
    &:before {
      font-size: 110px;
    }
  }
}


@include media-breakpoint-up(md) {

  .box-facts {
    height: 230px;
    &:before {
      font-size: 120px;
      line-height: 230px;
    }
  }

  .box-facts--interior {
    &:before {
      font-size: 150px;
    }
  }
}


@include media-breakpoint-up(lg) {
  .box-facts {
    height: 160px;
    &:before {
      font-size: 80px;
      line-height: 160px;
    }
  }
  .box-facts--interior {
    &:before {
      font-size: 100px;
    }
  }
}


@include media-breakpoint-up(xl) {
  .box-facts {
    height: 180px;
    &:before {
      font-size: 100px;
      line-height: 180px;
    }
  }

  .box-facts--interior {
    &:before {
      font-size: 130px;
    }
  }
}