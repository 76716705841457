.for_questions {
  font-family: 'BrandonGrotesque-Black';
  font-size: 14px;
  text-transform: uppercase;
}

.quote {
  font-family: 'BrandonText-Light';
  padding-bottom: 30px;
  &:before {
    font-family: 'BrandonGrotesque-Black';
    content: '»';
    font-size: 112px;
    line-height: 112px;
    display: inline-block;
    margin-top: -20px;
  }
}

.section--black .quote {
  color: #fff;
  &:before {
    color: $primary;
  }
}

.quote__author {
  font-size: 13px;
}

.button--center {
  margin-top: 20px;
}

.section--yellow a {
  color: #000;
}

.references {
  padding-top: 30px;
}

.news-teaser__text {
  padding-top: 35px;
}

.img--cycle {
  max-width: 450px;
  margin-bottom: 40px;
}

.img--process {
  padding-top: 100px;
  max-width: 850px;
}

.fourboxes__item {
  padding-bottom: 25px;
}

.fourboxes__itemimg {
  display: block;
  margin-bottom: 20px;
  height: 180px;
  background-position: center center;
  //background-size: cover;
  background-repeat: no-repeat;
  padding: 15px;
}

.fourboxes__itemimg--grey {
  background-color: $darkgrey-bg;
  background-size: 50%;
}

.fourboxes__itemtitle {
  margin-bottom: 10px;
}

.fourboxes__item-num {
  font-family: 'BrandonGrotesque-Black';
  font-size: 32px;
  color: $yellow-text;
}

.zigzag-list {
  padding-top: 35px;
  max-width: 815px;
  margin: 0 auto;
}

.zigzag-list__item {
  margin-bottom: 40px;
}

.background-col {
  background-position: center;
  background-size: cover;
  height: 100%;
  min-height: 300px;
}

.events__item {
  margin-bottom: 30px;
}

.contact-form__section {
  padding-top: 25px;
  padding-bottom: 25px;
}

.news__item img {
  max-width: 100%;
}

.news__item:not(.news__item--first),
.jobs__item:not(.jobs__item--first) {
  padding-top: 25px;
}

.news__item:not(.news__item--last),
.jobs__item:not(.jobs__item--last) {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 25px;
}

.news__item-image {
  padding: 10px;
  height: 180px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  //background-size: cover;
  display: block;
}

.news__item-image:hover span.button--yellow:before {
  background-color: #000;
  color: #fff;
}

.extendable-content {
  padding-top: 30px;
  display: none;
}

.contactperson,
.location {
  margin-bottom: 35px;
}

.locations {
  margin-bottom: 20px;
}


@include media-breakpoint-down(xs) {

  .zigzag-list__itemimg {
    display: block;
    margin: 0 auto 15px auto;
  }
  .zigzag-list__itembody {
    text-align: center;
  }

  .img--cycle-start {
    margin-top: 30px;
  }

}

@include media-breakpoint-down(sm) {
  .quote {
    margin-bottom: 20px;
  }
  .news__item-image {
    margin-bottom: 20px;
  }
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {

  .zigzag-list__itemimg {
    max-width: 50%;
  }

  .zigzag-list__itemimg {
    float: left;
    margin-right: 20px;
  }

  .zigzag-list__itemimg--right {
    float: right;
    margin-right: 0;
    margin-left: 20px;
  }

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  .for_questions {
    font-size: 18px;
  }

  .button--center {
    margin-top: 40px;
  }

  .quote__author {
    font-size: 16px;
  }

  .center-bottom {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
  }

  //.four-boxes {
  //  padding-top: 20px;
  //}

  .fourboxes__itemtitle {
    margin-bottom: 25px;
  }

  .fourboxes__item-num {
    font-size: 50px;
  }

  .events__item {
    margin-bottom: 70px;
  }

  .contact-form__section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .news__item:not(.news__item--first),
  .jobs__item:not(.jobs__item--first) {
    padding-top: 50px;
  }

  .news__item:not(.news__item--last),
  .jobs__item:not(.jobs__item--last) {
    padding-bottom: 50px;
  }

  .extendable-content {
    padding-top: 100px;
  }

  .contactperson {
    margin-bottom: 65px;
  }

  .img--cycle {
    margin-bottom: 60px;
  }

}


@include media-breakpoint-up(lg) {

  .quote__text {
    margin-bottom: 30px;
  }

  .quote__author {
    font-size: 18px;
  }

}


@include media-breakpoint-down(lg) {

  .contactperson__image {
    margin-bottom: 10px;
  }

}


@include media-breakpoint-up(xl) {

  .news__item-text--wide {
    width: 838px;
  }

  .contactperson .h3 {
    position: relative;
    top: -20px;
  }

  .contactperson__mail-button {
    position: absolute;
    bottom: 0;
  }

}