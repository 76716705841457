.reference {
  padding-top: 30px;
  //position: fixed;
  width: 100%;
  height: 100%;
  //top: 57px;
  left: 0;
  z-index: 100;
  color: #fff!important;
  display: none;
  //margin-top: -40px;
  margin-bottom: 0;
}

//.sticky-padding .reference {
//  top: 67px;
//}

.reference__main-image {
  margin-top: 20px;
  margin-bottom: 25px;
}

.reference__photocredit {
  font-size: 12px;
  margin-bottom: 15px;
  display: block;
  min-height: 26px;
}

//.reference__small-image {
//  padding: 20px;
//  width: 350px;
//  height: 210px;
//  max-width: 100%;
//  background-size: cover;
//  background-position: center;
//  background-repeat: no-repeat;
//}

.reference__gallery-image {
  display: block;
  position: relative;
  max-width: 550px;
  margin: 0 auto 20px auto;
}

.reference__gallery-image .button {
  margin-right: 15px;
  margin-bottom: 15px;
}

.reference__title {
  color: #fff;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 30px;
  &:before {
    display: block;
    color: #f0c401;
    font-size: 20px;
  }
}

.reference__srvclvl-title {
  margin-bottom: 20px;
}

.reference__attributes {
  padding-bottom: 25px;
}

.reference__attribute {
  font-family: 'BrandonGrotesque-Black';
  text-align: center;
  display: inline-block;
  width: 33.33333333333%;
  float: left;
}

.reference__attribute-num {
  font-size: 40px;
  display: block;
  color: $primary;
  line-height: 40px;
}

.reference__attribute-caption {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
}

//.reference__srvclvl-items {
//  padding-bottom: 20px;
//  max-width: 150px;
//}
//
//.reference__srvclvl-item {
//  color: #fff;
//  padding-bottom: 15px;
//  float: left;
//}

.reference__service-level {
  margin-bottom: 30px;
}


@include media-breakpoint-down(xs) {

  .reference__attribute {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

}


@include media-breakpoint-down(md) {

  //.reference__small-image {
  //  margin-bottom: 20px;
  //}

}


@include media-breakpoint-up(md) {

  .no-references {
    height: 750px;
    width: 100%;
    > p {
      display: block;
      position: relative;
      max-height: 100%;
      transform: translateY(-50%);
      top: 50%;
      text-align: center;
    }
  }

  .reference__logo {
    background-color: #000;
    background-position: center;
    width: 255px;
    height: 150px;
    margin-bottom: 50px;
    //background-size: 200px;
    background-repeat: no-repeat;
    text-align: center;
    > img {
      max-height: 100%;
    }
  }

  .reference__infocol {
    top: -205px;
  }

  .reference__main-image {
    margin-bottom: 15px;
  }

  .reference__title {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 40px;
    &:before {
      display: block;
      font-size: 30px;
    }
  }

  .reference__attributes {
    padding-bottom: 50px;
  }

  .reference__attribute-num {
    font-size: 60px;
    line-height: 60px;
  }

  .reference__attribute-caption {
    font-size: 24px;
    line-height: 24px;
  }

  //.reference__srvclvl-items {
  //  padding-bottom: 40px;
  //}
  //
  //.reference__srvclvl-item {
  //  padding-bottom: 30px;
  //}

}


@include media-breakpoint-up(lg) {

  .reference {
    //top: 182px;
  }

  //.reference__small-image {
  //  padding: 20px;
  //  width: 550px;
  //  height: 410px;
  //}

}