.pagination__list {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}

.pagination__listitem {
  font-family: 'BrandonGrotesque-Black';
  display: block;
  float: left;
}

.pagination__listitem:first-of-type {
  margin-right: 15px;
}

.pagination__listitem:last-of-type {
  margin-left: 15px;
}

.pagination__link {
  display: block;
  color: #000;
  font-size: 20px;
  line-height: 20px;
}

.pagination__listitem--active .pagination__link {
  color: $primary;
}

.pagination__listitem--disabled .pagination__link {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.pagination__link:not(.button) {
  padding-right: 5px;
  padding-left: 5px;
  top: 9px;
  position: relative;
}

.pagination__link:not(.button,.pagination__link--lastnum) {
  border-right: 1px solid black;
}


@include media-breakpoint-up(md) {

  .pagination__listitem:first-of-type {
    margin-right: 25px;
  }

  .pagination__listitem:last-of-type {
    margin-left: 25px;
  }

  .pagination__link {
    font-size: 24px;
    line-height: 24px;
  }

  .pagination__link:not(.button) {
    padding-right: 7px;
    padding-left: 7px;
    top: 7px;
  }

}
