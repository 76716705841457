h1, h2, h3, h4 {
  margin-top: 0;
  font-weight: normal;
}

h1, .h1 {
  font-family: 'BrandonGrotesque-Black';
  text-transform: uppercase;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 16px;
}

h1.text-center, .h1.text-center {
  margin-bottom: 30px;
}

h2, .h2, .h2 p {
  font-family: 'BrandonText-Light';
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 10px;
}

h3, .h3 {
  font-family: 'BrandonGrotesque-Black';
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}


.section-h {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  font-family: 'BrandonGrotesque-Black';
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 15px;
  padding-top: 22px;
  margin-bottom: 35px;
  &:after {
    font-family: 'icomoon';
    content: '\e90a';
    float: right;
    font-size: 42px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    top: 23px;
    background-color: #fff;
    right: 20px;
  }
}


// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {
  .section-h {
    margin-left: -12.5px;
    margin-right: -12.5px;
    padding-left: 12.5px;
  }
  .section-h__extension {
    display: none!important;
  }
}



// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {

  .section-h__extension {
    display: inline-block!important;
  }

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  h1,.h1 {
    font-size: 42px;
    line-height: 46px;
  }

  h2, .h2, .h2 p {
    font-size: 30px;
    line-height: 34px;
  }

  h3, .h3 {
    font-size: 24px;
    line-height: 26px;
  }

  .section-h {
    font-size: 18px; //oder 20
    line-height: 20px;
    margin-bottom: 50px;
    &:after {
      font-size: 58px;
      padding-left: 30px;
      padding-right: 30px;
      top: 26px;
      right: 40px;
    }
  }

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

  h1,.h1 {
    font-size: 50px;
    line-height: 55px;
  }

  h2, .h2, .h2 p {
    font-size: 38px;
    line-height: 41px;
    margin-bottom: 20px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
  .section-h {
    padding-left: 62.5px;
  }

  .section-h {
    &:after {
      right: 120px;
    }
  }
}