@include media-breakpoint-down(sm) {
  .wysiwyg-content img[align="right"],
  .wysiwyg-content img[align="left"] {
    width: 100%;
  }
}

.wysiwyg-content ul {
  padding: 0;
  list-style-type: none;
  font-family: 'BrandonText-Light';
}


.wysiwyg-content ul li {
  position: relative;
  padding: 0 0 0 16px;
  font-size: 13px;
  line-height: 18px;
  hyphens: auto;

  &:before {
    content: '';
    background-color: #000;
    display: block;
    width: 4px;
    height: 4px;
    margin-left: -15px;
    position: absolute;
    top: 11px;
    border-radius: 50%;
  }
}

.wysiwyg-content ol {
  padding: 0 0 0 16px;
  font-family: 'BrandonText-Light';
}

.wysiwyg-content ol li {

  font-size: 13px;
  line-height: 18px;
  hyphens: auto;
  position: relative;
  background-image: none;
}

.wysiwyg-content img {
  max-width: 100%;
}

.wysiwyg-content img[align="right"],
.wysiwyg-content img[align="left"] {
  margin-bottom: 20px;
}

.wysiwyg-content img[align="right"],
#wysiwyg-editor img[align="right"] {
  margin-left: 20px;
}

.wysiwyg-content img[align="left"],
#wysiwyg-editor img[align="left"] {
  margin-right: 20px;
}

@include media-breakpoint-up(md) {

  .wysiwyg-content img[align="right"],
  .wysiwyg-content img[align="left"] {
    max-width: 50%;
  }

  .wysiwyg-content ul li {
    font-size: 19px;
    line-height: 24px;
  }

  .wysiwyg-content ol li {
    font-size: 19px;
    line-height: 24px;
  }

}