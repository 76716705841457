@include media-breakpoint-down(md) {

  .header {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .slideout-overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);
  }

  #menu-desktop {
    display: none;
  }

  .navbar-toggle {
    font-family: 'Linearicons-Free';
    background-color: #fff;
    color: $primary;
    margin: 5px 0 0 0;
    border: none;
    border-radius: 0;
    width: 35px;
    height: 33px;
    font-size: 30px;
    line-height: 33px;
    padding: 0;
    &.active:before {
      color: #000;
      content:"\e870";
    }
  }

  .navbar-toggle--left {
    float: left;
  }

  .navbar-toggle--right {
    float: right;
  }

  .slideout-open .mobile-nav {
    display: block!important;
  }

  .mobile-nav {
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    z-index: -1;
    overflow: auto;
  }

  .mobile-nav__mainlink {
    color: #000;
    margin-bottom: 10px;
    display: inline-block;
    font-family: 'BrandonText-Light';
    &:hover {
      color: #fff;
    }
  }

  .mobile-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .mobile-nav .level1 > li {
    padding-top: 8px;
    padding-bottom: 8px;
    display: block;
  }

  .mobile-nav .level1 > li > a {
    font-family: 'BrandonText-Light';
    text-transform: uppercase;
    font-size: 18px;
  }

  .mobile-nav .level2 {
    padding-left: 25px;
    transition: opacity 1s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  .mobile-nav .level2 > li > a {
    font-family: 'BrandonText-Light';
    font-size: 16px;
    color: #fff;
    &:hover {
      color: $primary;
    }
  }

  .mobile-nav .dropdown.open > .level2 {
    padding-bottom: 15px;
    padding-top: 10px;
    opacity: 1;
    height: auto;
  }


  .mobile-nav--black {
    background-color: #000;
    .level1 > li {
      border-bottom: 1px solid $primary;
      > a {
        color: #fff;
        &:hover {
          color: $primary;
        }
      }
    }
  }

  .mobile-nav--yellow {
    background-color: $primary;
    .level1 > li {
      border-bottom: 1px solid #fff;
      > a {
        color: #000;
        &:hover {
          color: #fff;
        }
      }
    }
  }

}


#main-nav {
  margin-top: 6px;
}

#main-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#main-nav > ul {
  text-align: center;
  font-size: 18px;
}

#main-nav .level1 > li {
  display: inline-block;
}

#main-nav .level1 > li > a {
  font-family: 'BrandonGrotesque-Black';
  text-transform: uppercase;
  display: inline-block;
  padding: 0 15px;
  color: #000;
  &:hover {
    color: $primary;
  }
}

#main-nav .level2 {
  font-family: 'BrandonText-Light';
  font-size: 17px;
  text-align: left;
  position: absolute;
  left: 15px;
  top: 100%;
  padding: 20px 1000rem;
  margin: 0 -1000rem;
  z-index: 101;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 1s ease;
}

#main-nav .level2 > li {
  padding: 8px 0;
  line-height: 20px;
}

#main-nav .level2 > li > a {
  text-align: left;
  white-space: nowrap;
  &:hover {
    color: $primary;
  }
}

#main-nav .dropdown:hover {
  padding-bottom: 21px;
  > .level2 {
    visibility: visible!important;
    opacity: 1;
  }
}

#main-nav .level2 > li > a {
  color: #fff;
}

.nav-divider {
  padding-bottom: 10px!important;
}

.nav-divider:after {
  content: '';
  width: 160px;
  border-bottom: 1px solid $primary;
  display: block;
  margin-top: 20px;
}

// http://jsfiddle.net/doubleswirve/xbLrW/2/



// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

  .header {
    transition: background 0.5s ease;
  }

  .navbar-toggle {
    display: none;
  }

  #main-nav {
    margin-top: 6px;
  }

  #main-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #main-nav > ul {
    text-align: center;
    font-size: 18px;
  }

  #main-nav .level1 > li {
    display: inline-block;
    z-index: 1;
  }

  #main-nav .level1 > li > a {
    font-family: 'BrandonGrotesque-Black';
    text-transform: uppercase;
    display: inline-block;
    padding: 0 15px;
    color: #000;
    &:hover {
      color: $yellow-text;
    }
  }

  #main-nav .level1 > li.active > a {
    color: $yellow-text;
  }

  #main-nav .level1 > li > a.icon-home {
    text-indent: -9999px;
    display: inline-block;
    width: 58px;
    &:before {
      float: left;
      text-indent: 0;
      font-size: 28px;
      line-height: 20px;
    }
  }


  #main-nav .level2 {
    font-family: 'BrandonText-Light';
    font-size: 16px;
    text-align: left;
    position: absolute;
    left: 15px;
    top: 100%;
    padding: 20px 1000rem;
    margin: 0 -1000rem;
    z-index: 101;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 1s ease;
  }

  #main-nav .level2 > li {
    padding: 6px 0;
    line-height: 16px;
  }

  #main-nav .level2 > li > a {
    text-align: left;
    white-space: nowrap;
    &:hover {
      color: $primary;
    }
  }

  #main-nav .dropdown:hover {
    padding-bottom: 21px;
    > .level2 {
      visibility: visible!important;
      opacity: 1;
    }
  }

  .sticky {
    padding-bottom: 0!important;
  }

  .sticky #main-nav .level1 > li {
    padding-bottom: 10px;
  }

  .sticky #main-nav .dropdown:hover {
    padding-bottom: 10px;
  }

  #main-nav .level2 > li > a {
    color: #fff;
  }

  .nav-divider {
    padding-bottom: 8px!important;
  }

  .nav-divider:after {
    content: '';
    width: 150px;
    border-bottom: 1px solid $primary;
    display: block;
    margin-top: 18px;
  }

}