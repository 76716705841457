.section {
  position: relative;
}

.section--mb-50 {
  margin-bottom: 20px;
}

.section--mb-100 {
  margin-bottom: 30px;
}

.section--padding {
  padding-top: 25px;
  padding-bottom: 25px;
}

.section--yellow {
  background-color: $primary;
}

.section--black {
  background-color: #000;
}

.section--grey {
  background-color: $grey-bg;
}

.section--darkgrey {
  background-color: $darkgrey-bg;
}

.section--extendable {
  padding-bottom: 30px;
}

.section--extended {
  background-color: $grey-bg;
}

.header + .section--text {
  margin-top: 30px;
  margin-bottom: 30px;
}


@include media-breakpoint-up(md) {

  .section--mb-50 {
    margin-bottom: 50px;
  }

  .section--mb-100 {
    margin-bottom: 100px;
  }

  .section--extendable {
    padding-bottom: 60px;
  }

  .section--padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .header + .section--text {
    margin-top: 50px;
    margin-bottom: 50px;
  }

}