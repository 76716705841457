@media (max-width: 1199.98px) {
  .overflow-fix {
    overflow-x: hidden;
  }
}

html {
  overflow-x: hidden;
}

body {
  //overflow-x: hidden; verursacht Problem mit Sticky Nav
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

p {
  font-family: 'BrandonText-Light';
  font-size: 13px;
  line-height: 18px;
  hyphens: auto;
}

p a:not(.button) {
  color: #000;
  text-decoration: underline;
  &:hover {
    color: #000;
    text-decoration: underline;
  }
}

p.hyphens-exclude {
  hyphens: none;
}

hr:not(.footer-divider) {
  border-top: 1px solid #bfbfbf;
}

.custom-row {
  margin-left: -12.5px;
  margin-right: -12.5px;
}

.container-inner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.slickarrow {
  position: absolute;
  z-index: 1000;
  top: 50%;
  transform: translate(0,-50%);
}

.slickarrow--next {
  right: 0;
}

.slickarrow--previous {
  left: 0;
}

//fixes slick slider flickering bug in chrome
.slick-track,
.slick-list {
  -webkit-perspective: 2000px;
  -webkit-backface-visibility: hidden;
}

.scrolltop {
  display: none;
  position: fixed;
  right: 0;
  bottom: 50px;
  z-index: 9999;
}

.optionbar {
  position: fixed;
  right: 0;
  display: none;
  top: 150px;
  z-index: 500;
}

.optionbar__item {
  position: relative;
}

.optionbar__item > .button {
  display: block;
  margin-bottom: 3px;
  z-index: 2;
  position: relative;
}

.search-form__input {
  border: none;
  outline: none;
  text-transform: uppercase;
}

.optionbar__flyout {
  overflow: hidden;
  //display: none;
  position: absolute;
  height: 38px;
  line-height: 38px;
  right: -280px;
  visibility: hidden;
  top: 0;
  background-color: #fff;
  //z-index: 1;
  color: #000;
  padding-right: 53px;
  padding-left: 15px;
  box-shadow: 0 0 20px #ccc;
  text-transform: uppercase;
  //font-family: 'BrandonText-Light';
  font-size: 14px;
  transition: right .3s ease;
  &:hover {
    color: #000;
  }
}

.optionbar__flyout--visible {
  right: 0;
  visibility: visible;
}

.search-form__submit {
  top: 0;
  right: 0;
  position: absolute;
  z-index: 3;
}

.badge-appstore {
  height: 50px;
}

.badge-playstore {
  height: 74px;
}

.info-circle {
  display: inline-block;
  background-color: $primary;
  height: 18px;
  border-radius: 75px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  color: #000;
  float: left;
  font-family: 'BrandonGrotesque-Black';
  margin-left: 10px;
  font-size: 16px;
}


.tooltip .arrow:before {
  border-right-color: $primary!important;
}

.tooltip-inner {
  background-color: $primary;
  color: #000;
}


@include media-breakpoint-down(xs)  {

  .container-inner.narrowed-xs {
    padding: 0 20px;
  }

  .small-gutters {
    margin-right: -6px;
    margin-left: -6px;
    > [class*="col-"] {
      padding-right: 6px;
      padding-left: 6px;
    }
  }

}


@include media-breakpoint-down(sm) {

  .slick-dots li {
    margin: 0 2px;
  }

  .slick-dots li button:before {
    font-size: 20px;
  }

}


@include media-breakpoint-up(md) {

  p {
    font-size: 19px;
    line-height: 24px;
  }

  .optionbar__flyout {
    height: 57px;
    line-height: 57px;
    padding-right: 87px;
    padding-left: 30px;
    font-size: 17px;
  }

  .info-circle {
    margin-top: 3px;
  }

}



@include media-breakpoint-up(xl) {

  .container-inner {
    padding-right: 62.5px;
    padding-left: 62.5px;
  }

  .slick-dots li {
    margin: 0 10px;
  }

}