body.sticky-padding {
  padding-top: 115px;
}

.header.sticky {
  position: sticky;
  top: 0;
  z-index: 1071;
  height: auto;
  padding-top: 5px;
  padding-bottom: 10px;
  box-shadow: 0 0 20px #ccc;
  background-color: #fff;
}

.logo {
  width: 100%;
  max-width: 200px;
}

.logo img {
  width: 100%;
}

.logo--desktop {
  top: -8px;
  position: relative;
}

.themepic {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.startclaim {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.startclaim__text {
  font-family: 'BrandonText-Light';
  font-size: 18px;
  line-height: 22px;
}

.global-claim {
  font-family: 'BrandonText-Light';
  float: left;
  line-height: 20px;
  font-size: 18px;
}

.themepic {
  height: 250px;
}

.srvclvl-overlay {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 200px;
  top: 50px;
}

.srvclvl-overlay__square {
  width: 50%;
  float: left;
  position: relative;
  height: 100px;
}

.srvclvl-overlay__square--l {
  background-color: #fff;
  padding: 5px;
}

.srvclvl-overlay__icon {
  display: block;
  font-size: 90px;
}

.srvclvl-overlay__square--r {
  background: rgba(255, 220, 0, 0.8);
}

.srvclvl-overlay__text {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.srvclvl-overlay__line {
  font-family: 'BrandonGrotesque-Black';
  text-transform: uppercase;
  display: block;
  line-height: 13px;
  text-align: center;
  font-size: 13px;
  padding-bottom: 5px;
}

.srvclvl-overlay__line--white {
  color: #fff;
}


// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(md) {

  .logo {
    display: block;
    text-align: center;
    margin: 0 auto;
  }

}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  .themepic:not(.themepic--start) {
    height: 545px;
  }

  .themepic--start {
    height: 585px;
  }

  .themepic-outer {
    margin-bottom: 40px;
  }

  .startclaim {
    height: 120px;
    padding-top: 20px;
  }

  .startclaim__text {
    font-size: 32px;
    line-height: 34px;
  }

  .srvclvl-overlay {
    width: 450px;
    top: 160px;
  }

  .themepic--start .srvclvl-overlay {
    top: 192px;
  }

  .srvclvl-overlay__square {
    height: 225px;
  }

  .srvclvl-overlay__square--l {
    //padding: 10px;
  }

  .srvclvl-overlay__icon {
    //font-size: 205px;
    font-size: 215px;
  }

  .srvclvl-overlay__text {
    padding-bottom: 20px;
  }

  .srvclvl-overlay__line {
    line-height: 24px;
    font-size: 24px;
    padding-bottom: 10px;
  }

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

  .header {
    padding-top: 40px;
    padding-bottom: 21px;
    height: 182px;
  }


  .sticky {
    .sticky-hidden {
      display: none;
    }
  }

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {

  .logo {
    margin-right: 62.5px;
  }

  .startclaim {
    padding-top: 15px;
  }

  .startclaim__text {
    font-size: 38px;
    line-height: 41px;
  }

}