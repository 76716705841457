//.big-slider {
//  margin-bottom: 30px;
//}

.container-inner {
  //> .big-slider {
  //  margin-bottom: 70px;
  //}
  .big-slider__item {
    padding: 15px;
  }
}

.big-slider__item-wrapper {
  position: relative;
  height: 100%;
}

.big-slider__item {
  height: 300px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.big-slider__item--bg-contain {
  background-size: contain!important;
}

.big-slider__text {
  font-family: 'BrandonText-Light';
  font-size: 26px;
  text-transform: uppercase;
  padding-left: 25px;
  display: block;
  line-height: 38px;
  padding-top: 40px;
  margin-bottom: 30px;
}

.big-slider__item-wrapper div {
  height: 100%;
}

.big-slider__text--bottom {
  //padding-top: 150px!important;
  padding-top: 0!important;
  position: absolute;
  bottom: 0;
  margin-bottom: 6px;
}

.big-slider__text span {
  box-shadow: 5px 0 0 rgba(255, 220, 0, .7), -10px 0 0 rgba(255, 220, 0, .7);
  background-color: rgba(255, 220, 0, .7);
  box-decoration-break: clone;
  color: #000;
}


@include media-breakpoint-down(sm) {

  .big-slider__text + .button {
    margin-left: 15px;
  }

}


@include media-breakpoint-up(md) {

  //.big-slider {
  //  margin-bottom: 50px;
  //}

  .big-slider__item {
    height: 486px;
  }

  .big-slider__text {
    font-size: 50px;
    line-height: 70px;
    padding-top: 85px;
  }

  .big-slider__text--bottom {
    //padding-top: 240px!important;
  }

  .big-slider__text span {
    box-shadow: 20px 0 0 rgba(255, 220, 0, .7), -25px 0 0 rgba(255, 220, 0, .7);
  }

  .big-slider__item-wrapper {
    padding-left: 15px;
  }

}