.tile {
  display: block;
  margin-bottom: 20px;
  transition: box-shadow .3s;
  &:hover {
    box-shadow: 0 0 20px #ccc;
  }
}

.tile__img {
  display: block;
  min-height: 100px;
  background-position: center;
  background-size: cover;
}

.tile__caption {
  display: block;
  color: #000;
  margin-bottom: 0;
}

.section--black .tile__caption {
  color: #fff;
}

.tiles--refcats {
  padding-bottom: 30px;
}

.stars-1:before {
  content:'*';
}
.stars-2:before {
  content:'* *';
}
.stars-3:before {
  content:'* * *';
}
.stars-4:before {
  content:'* * * *';
}
.stars-5:before {
  content:'* * * * *';
}

.tiles--refcats {
  .tile__img {
    height: 150px;
  }
  .tile__caption {
    padding-bottom: 10px;
    padding-right: 5px;
    padding-top: 10px;
    padding-left: 10px;
    //&:before {
    //  font-family: 'Linearicons-Free';
    //  content: "\e876";
    //  color: #000;
    //  font-size: 17px;
    //  text-align: center;
    //  float: left;
    //  width: 32px;
    //  height: 30px;
    //}
  }
}

.tiles--reflist {
  .tile__img {
    height: 130px;
  }
  .tile__caption {
    padding: 15px;
    &:before {
      display: block;
      color: $yellow-text;
      font-size: 20px;
    }
  }
}


@include media-breakpoint-up(md) {

  .tile {
    margin-bottom: 35px;
  }

  .tiles--refcats {
    .tile__img {
      height: 300px;
    }
    .tile__caption {
      line-height: 57px;
      padding-bottom: 5px;
      padding-top: 5px;
      padding-left: 0;
      &:before {
        text-align: center;
        font-family: 'Linearicons-Free';
        content: "\e876";
        color: #000;
        float: left;
        width: 57px;
        height: 57px;
        font-size: 34px;
        line-height: 57px;
      }
    }
  }

  .tiles--reflist {
    .tile__img {
      min-height: 260px;
    }
    .tile__caption {
      padding: 20px 25px 25px 25px;
      &:before {
        font-size: 30px;
      }
    }
  }

}