//
// Custom fonts (e.g. for inserting icomoon font)
// --------------------------------------------------


//Linearicons Free

@font-face{
  font-family:"BrandonGrotesque-Black";
  src:url("#{$custom-font-path}/5579764/8490e6a8-a82a-49a1-8b88-4d2796149009.eot?#iefix");
  src:url("#{$custom-font-path}/5579764/8490e6a8-a82a-49a1-8b88-4d2796149009.eot?#iefix") format("eot"),url("#{$custom-font-path}/5579764/ca0293d5-fe3a-478d-b3d8-7f5748b3a8df.woff2") format("woff2"),url("#{$custom-font-path}/5579764/851575f1-9507-4abe-902c-9a96cf9aaa84.woff") format("woff"),url("#{$custom-font-path}/5579764/70115c82-382c-454d-8482-851deb08bf4a.ttf") format("truetype");
}
@font-face{
  font-family:"BrandonText-Light";
  src:url("#{$custom-font-path}/5580070/ef72db82-4690-4577-8ca6-1f986815c3bb.eot?#iefix");
  src:url("#{$custom-font-path}/5580070/ef72db82-4690-4577-8ca6-1f986815c3bb.eot?#iefix") format("eot"),url("#{$custom-font-path}/5580070/2b8d3660-1a97-4e2b-8637-c27c47ef6e4a.woff2") format("woff2"),url("#{$custom-font-path}/5580070/f2735d8e-dece-4e49-baeb-a1211c308cd2.woff") format("woff"),url("#{$custom-font-path}/5580070/98071f54-bc79-4389-8363-c1c4532989fa.ttf") format("truetype");
}


//Custom Font

@font-face {
  font-family: 'icomoon';
  src:  url('#{$custom-font-path}/icomoon.eot?tiyca9');
  src:  url('#{$custom-font-path}/icomoon.eot?tiyca9#iefix') format('embedded-opentype'),
  url('#{$custom-font-path}/icomoon.ttf?tiyca9') format('truetype'),
  url('#{$custom-font-path}/icomoon.woff?tiyca9') format('woff'),
  url('#{$custom-font-path}/icomoon.svg?tiyca9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//.icon-youtube-square:before {
//  content: "\e904";
//}
.icon-linkedin-square:before {
  content: "\e900";
}
//.icon-google-plus-square:before {
//  content: "\e902";
//}
.icon-xing-square:before {
  content: "\e901";
}
//.icon-twitter-square:before {
//  content: "\e900";
//}
.icon-facebook-square:before {
  content: "\e902";
}

.icon-1:before {
  content: "\e90d";
}
.icon-2:before {
  content: "\e90f";
}
.icon-3:before {
  content: "\e910";
}
.icon-4:before {
  content: "\e90e";
}
.icon-home:before {
  content: "\e909";
}
.icon-logo-4:before {
  content: "\e90a";
}
.icon-map:before {
  content: "\e90b";
}
