.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.bottom-right-25 {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.item-wrapper {
  height: 100%;
  position: relative;
}

.shadow {
  box-shadow: 0 0 20px #ccc;
}

.bg-grey {
  background-color: $grey-bg;
}

.bg-white {
  background-color: #fff;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-bottom {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.no-margin {
  margin-bottom: 0!important;
  margin-top: 0!important;
}

.no-padding {
  padding-bottom: 0!important;
  padding-top: 0!important;
}

.slick-no-slide .slick-track {
  width: 100%!important;
  text-align: center;
  transform: inherit!important;
}

.slick-no-slide .slick-slide {
  float: none;
  display: inline-block;
}

.vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@include media-breakpoint-down(xs) {
  .center-bottom {
    max-width: 230px;
  }
}