//.modal-backdrop.show {
//  background-color: $darkgrey-bg;
//  //background-color: rgba(72,72,72,0.8);
//  opacity: 0.95;
//}

.gallery-modal {
  padding-top: 20px;
}

.gallery-modal .modal-dialog {
  margin-top: 0;
}

.gallery-modal .modal-body {
  padding: 0;
}

.gallery-modal .modal-content {
  background-color: #000!important;
  border: none!important;
  border-radius: 0!important;
  color: #fff!important;
}

.close-gallery-modal {
  float: right;
  margin-bottom: 10px;
  margin-right: 8px;
}

.gallery-slider-nav__item.slick-current {
  border: 1px solid $primary;
}

.gallery-slider__item-desc {
  padding: 20px 25px 20px 25px;
  margin: 0;
}

.gallery-slider-nav-outer {
  padding: 0 25px 25px 25px;
  margin-right: -8px;
  margin-left: -8px;
}

.gallery-slider-nav__item {
  //height: 60px;
  //background-size: cover;
  //background-repeat: no-repeat;
  //background-position: center center;
  margin: 0 4px;
  border: 1px solid transparent;
}

@include media-breakpoint-down(xs) {

  .gallery-slider-nav-outer {
    padding: 0 15px 15px 15px;
  }

  .gallery-slider__item-desc {
    padding: 10px 15px 10px 15px;
  }

}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {

  .gallery-slider-nav__item {
    //height: 80px;
    margin: 0 8px;
  }

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  .close-gallery-modal {
    margin-right: 30px;
    margin-bottom: 20px;
  }

  .gallery-modal .modal-dialog {
    max-width: 700px;
  }

  .gallery-slider-nav__item {
    //height: 120px;
  }

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

  .close-gallery-modal {
    margin-right: 40px;
  }

  .gallery-modal .modal-dialog {
    max-width: 900px;
  }

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {

  .gallery-modal .modal-dialog {
    max-width: 1000px;
  }

  //#gallery-modal {
  //  padding-top: 50px;
  //}

}

@media (min-width: 1500px) {
  .gallery-modal .modal-dialog {
    max-width: 1200px;
  }
}