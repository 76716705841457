.button {
  display: inline-block;
  border: none;
  background: none;
  padding: 0;

  &:before {
    font-family: 'Linearicons-Free';
    width: 38px;
    height: 38px;
    font-size: 17px;
    line-height: 38px;
    text-align: center;
    float: left;
    transition: background-color .3s ease-out;
  }
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

.button__label {
  font-family: 'BrandonText-Light';
  font-size: 13px;
  line-height: 13px;
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  color: #000;
  text-align: left;

  //oldschool
  display: table-cell;
  vertical-align: middle;

  //flexbox
  //float: left;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
}

.button--center {
  display: inline-block;
  &:before {
    float: none;
    display: inline-block;
  }
}

.button.button--collapse {
  display: none;
}

.button[aria-expanded='true']:before {
  //font-family: 'Linearicons-Free';
  content:"\e880";
  background-color: #000;
  color: #fff;
}

.section--extended .button[aria-expanded='true']:before {
  background-color: #fff;
  color: #000;
  content:"\e880";
}

.section--black .button__label {
  color: #fff;
}

//Icons

//Chevrons
.button--chevron-r:before {
  content:"\e876";
}

.button--chevron-l:before {
  content:"\e875";
}

.button--chevron-up:before {
  content:"\e873";
}

.button--chevron-down:before {
  content:"\e874";
}


//Arrows
.button--arrow-r:before {
   content:"\e87a";
 }

.button--arrow-l:before {
  content:"\e879";
}


//Others
.button--earth:before {
  content:"\e853";
}

.button--pencil:before {
  content:"\e802";
}

.button--marker:before {
  content:"\e833";
}

.button--magnifier:before {
  content:"\e86f";
}

.button--menu:before {
  content:"\e871";
}

.button--camera:before {
  content:"\e826";
}

.button--checkmark:before {
  content:"\e87f";
}

.button--extend:before,
.button--plus:before {
  content:"\002B";
  line-height: 35px;
  //font-family: 'icomoon';
  //speak: none;
  //font-style: normal;
  //font-weight: normal;
  //font-variant: normal;
  //text-transform: none;
  //line-height: 1;
  //content:"\e913";
  //font-size: 17px!important;
}

.button[aria-expanded='true']:before {
  line-height: 38px!important;
}

.button--close:before {
  content:"\e870";
}

.button--video:before {
  content:"\e824";
}

.button--mail:before {
  content:"\e818";
}

.button--file:before {
  content:"\e81d";
}

.button--collapse:before {
  content:"\e880";
}



//Colors
.button--white:before {
  background-color: #fff;
  color: #000;
}

.button--white:hover:before {
  background-color: #000;
  color: #fff;
}


.button--black:before {
  background-color: #000;
  color: #fff;
}

.button--black:hover:before {
  background-color: $primary;
  color: #000;
}


.button--yellow:before {
  background-color: $primary;
  color: #000;
}

.button--yellow:hover:before {
  background-color: #000;
  color: #fff;
}

.section--black .button--yellow:hover:before {
  background-color: #fff;
  color: #000;
}

.button-big {
  display: block;
  font-family: 'BrandonGrotesque-Black';
  text-transform: uppercase;
  line-height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  text-align: left;
  border: none;
  cursor: pointer;
  &:after {
    content: '>';
    font-size: 60px;
    float: right;
    position: relative;
    top: -6px;
  }
}

.button-big--yellow {
  background-color: $primary;
  color: #000;
  &:hover {
    color: #000;
  }
}

@include media-breakpoint-down(xs) {
  .button.float-right {
    float: none!important;
  }
}


// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  .button:not(.button--small) {
    &:before {
      width: 57px;
      height: 57px;
      font-size: 34px;
      line-height: 57px;
    }
  }

  .button--extend:before,
  .button--plus:before {
    line-height: 51px!important;
  }

  .button__label {
    font-size: 19px;
    line-height: 21px;
    height: 57px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .button--small .button__label {
    height: 38px!important;
  }

  .wysiwyg-content .button__label {
    padding-right: 0;
  }

  .button-big {
    line-height: 80px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    &:after {
      font-size: 80px;
      top: -7px;
    }
  }

  //.button--plus:before,
  //.button--extend:before {
  //  line-height: 53px!important;
  //}

  .button[aria-expanded='true']:before {
    line-height: 57px!important;
    //font-size: 34px!important;
  }

}

